body.manufacturer {
	.manufacturerWrap {
		margin-bottom: 20px;
		.manufacturer {
			margin-bottom: 10px;

			a {
				position: relative;
			}
		}
	}
	
	h2 {
		margin-bottom: 20px;

		&.accHeading {
			margin-bottom: 0;
		}
	}

	.modelWrap {
		margin-bottom: 20px;

		.model {			
			.innerWrap {
				text-align: center;
				border: 1px solid $primary;
				padding: 10px;
				margin-bottom: 15px;
			}
			a {
				position: relative;
			}
		}
	}

	.main-nav li.lang .sub {
		@include breakpoint(medium){
			padding-top: 24px !important;
		}
	}
}

body.demos,
body.damos {
	table {
		width: 100%;

		td.manufacturer {
			background-color: rgba($light, .8);
			color: $secondary;
			font-size: 24px;
			text-transform: uppercase;
		}

		tr.brand {
			td {
				border-top: 3px solid rgba($light, .8);
				text-transform: uppercase;
			}
		}

		tr.type {
			td {
				background-color: rgba($light, .4);
			}
		}
	}

	tr.action {
		background-color: $warning;
	}

	p.highlight {
		font-size: 24px;
	}
}

body.index {
	.index-content {
		font-size: 18px;
	}
}