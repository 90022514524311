.footer {
	margin-top: 38px;
    border-top: 2px solid white;
    padding: 40px 0 40px;

    .phone {
    	display: block;
    	text-align: center;

    	@include breakpoint(medium){
    		text-align: right;
    	}
    }

    .mail {
    	display: block;
    	text-align: center;

    	@include breakpoint(medium){
    		text-align: left;
    	}
    }

    .copyright {
    	margin-top: 20px;
    }
}