.accHeading {
	border: 1px solid $third;
    padding: 10px 10px;
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &.open {
    	border-bottom: 0;
    }
}

.accHeading + div {
	max-height: 0;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	margin-bottom: 20px;
	border: 0;
	padding: 0;
}

.accHeading.open + div {
	max-height: 100000px;
	padding: 10px 10px;
	border: 1px solid $third;
}