body {
	font-size: 16px;
	background-color: $secondary;
	color: $primary;
}

h1 {
	margin-bottom: 40px;
	font-size: 26px;
	line-height: 1.2;
	.lead {
		font-size: 36px;
		display: block;
		font-weight: 700;
	}
}

h2, .h2 {
	margin-top: 0;
}

a {
	color: $third;
	position: relative;

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background-color: $primary;
		transform: scaleX(0);
		transform-origin: left;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		@include position(absolute, 100% null null 0);
	}
	&:hover,
	&:active,
	&:visited {
		text-decoration: none;
	}

	&:hover {
		color: $third;
		
		&:before {
			transform: scaleX(1);
			opacity: 1;
		}	
	}
}

p {
	margin-bottom: 18px;
}

.main {
	padding-top: 20px;
}

table {
    margin-bottom: 20px;
	tr {
		td {
			border: 1px solid $light;    
			padding: 5px 10px;
		}
	}
}