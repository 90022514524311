/*
 * Bootstrap core including
 */

// Core variables and mixins
@import "fontawesome/font-awesome";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";


/*
 * Bootstrap core including END
 */
@import "common/variables";
@import "common/mixins";
@import "common/fonts";
@import "common/functions";


@import "vendor/lightslider";
@import "vendor/accordeon";


@import "layout/shared";
@import "layout/globals";
@import "layout/header";
@import "layout/navigation";
@import "layout/footer";
@import "layout/pages";

body {
  font-family: 'Open Sans', sans-serif;
}

.fa-btn {
  margin-right: 6px;
}