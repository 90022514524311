.header {
	border-bottom: 3px solid $third;
	position: relative;
	overflow: hidden;
	
	@include breakpoint(medium){
		min-height: calc(100vh + 3px);	
		overflow: visible;
	}

	body:not(.index) & {
		@include breakpoint(medium){
			min-height: 200px;	
		}
	}

	.slideshow-wrapper {
		width: 100%;
		@include position(absolute, 0 0 0 0);
    	z-index: 1;
		
		.lSSlideOuter {
			height: 100% !important;
		}

		.lSSlideWrapper {
			height: 100% !important;
		}

		#lightslider {
			height: 100% !important;
			li {
				display: block;
				height: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

				@for $i from 1 through 3 {
					&:nth-child(#{$i}) {
						background-image: image-url('slideshow/item#{$i}.jpg');
					}
				}
			}
		}
	}

	.slideshow-content {
		position: relative;
		z-index: 2;
    	padding: 70px 0 80px;
    	overflow: hidden;

    	@include breakpoint(medium){
    		padding: 200px 0 80px;	
    	}

		.claim {
			padding: 10px;
			position: relative;
			display: inline-block;
			max-width: 100%;
			background-color: $secondary;
			z-index: 1;

			&:before {
				content: "";
				display: block;
				@include position(absolute, 40px null null 40px);
				border: 2px solid $light;
				width: 100%;
				height: 100%;
				z-index: -2;
			}

			&:after {
				content: "";
				display: block;
				@include position(absolute, 0 null null 0);
				background-color: $secondary;
				border: 2px solid $third;
				width: 100%;
				height: 100%;
				z-index: -1;
			}

			span.main {
				text-transform: uppercase;
			    font-style: normal;
			    font-weight: 700;
			    font-size: 30px;
			    display: inline-block;
			    line-height: 1.5;
			    padding: 0 14px;

			    @include breakpoint(tiny){
				    font-size: 40px;
			    }

			    @include breakpoint(medium){
			    	font-size: 50px;
			    }

			    .highlight {
			    	color: $third;
			    	font-style: italic;
			    	display: block;
			    }
			}
		}

		.go-to-content-wrapper {
			display: none;

			@include breakpoint(medium){
				// display: block;
			    padding: 250px 0 0;
			    text-align: center;
	    		margin-bottom: 20px;

			}
		    a {
		    	display: inline-block;
			    border: 2px solid #fff;
			    width: 80px;
			    height: 80px;
			    background-color: white;
			    border-radius: 50%;
			    text-align: center;
				position: relative;
				color: $third;
				transition: all 0.3s ease-in-out;
    			color: $secondary;

    			&:before {
    				content: none;
    			}

				&:hover {
					color: $primary;
					background-color: $secondary;
				}

			    i {
			    	font-size: 45px;
    				font-weight: 300;
    				@include position(absolute, 0 0 null 0);

    				&:first-child {
    					top: 4px;
    				}

    				&:last-child {
    					top: 27px;
    				}
			    }
		    }
		}
	}
}