.navbar {
	background-color: $secondary;
	color: $light;
	border-bottom: 2px solid $third;
	z-index: 10;
	position: relative;

	@include breakpoint(medium){
		@include position(fixed, 0 0 null 0);
	}
	
	.col-wrapper {
		text-align: center;

		@include breakpoint(large){
			text-align: left;
		}
		.logo {
			display: inline-block;
			width: 100%;
			max-width: 240px;    
			margin: 20px 0 20px 55px;
			float: none;

			@include breakpoint(tiny){
				margin: 20px 0 20px;
			}
			@include breakpoint(large){
				float: left;
			}
			@include breakpoint(large){
				max-width: 300px;
			}

			color: $primary;
			position: relative;

			img {
				display: block;
				width: 100%;
				max-width: 100%;
			}

			&:hover {
				text-decoration: none;	
			}
		}

		.main-nav {
			width: 100%;
			margin: 15px 0 20px 0;
			max-height: 0px;
			transition: all 0.3s ease-in-out;
			overflow: hidden;
			text-align: left;
			padding: 0;

			@include breakpoint(medium){
				overflow: visible;
    			padding: 0 0 0 40px;
			}

			&.active {
				max-height: 4000px;

				@include breakpoint(medium){
					max-height: none;	
				}
			}

			@include breakpoint(medium){
				//max-width: calc(100% - 240px);
				float: left;
				max-height: none;
				text-align: right;
			}

			@include breakpoint(large){
				max-width: calc(100% - 300px);
			}

			li {
    			display: block;
    			padding: 15px 10px 10px;
    			position: relative;
    			vertical-align: middle;

    			@include breakpoint(medium){
					display: inline-block;
    			}
				
				&:hover {
					.sub {
						transform: scaleY(1);
						opacity: 1;
						visibility: visible;
					}
				}

				&:last-child {
					padding-right: 0;
				}

				a,
				span {
					display: block;
					position: relative;
    				font-size: 16px;
    				color: $third;
					
    				@include breakpoint(giant){
    					font-size: 20px;
    				}

					&:before {
						content: "";
						display: block;
						width: 100%;
						height: 2px;
						background-color: $secondary;
						transform: scaleX(0);
						transform-origin: left;
						transition: all 0.3s ease-in-out;
						opacity: 0;
						@include position(absolute, 100% null null 0);
					}
				}

				span {
					cursor: pointer;

					@include breakpoint(medium){
						cursor: inherit;
					}
				}
				
				&.active {
					a, 
					span {
						text-decoration: none;

						&:before {
							transform: scaleX(1);
							opacity: 1;
						}	
					}
				}

				a {
					&:hover {
						text-decoration: none;

						&:before {
							transform: scaleX(1);
							opacity: 1;
						}	
					}
				}

				.sub {
					overflow: hidden;
					max-height: 0;
					transition: all 0.3s ease-in-out;

					@include breakpoint(medium){
						max-height: none;
						display: block;
						@include position(absolute, 100% 0 null null);
						background-color: $light;
						box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	    				padding-left: 0;
						transform: scaleY(0);
						transform-origin: top;
						opacity: 0;
						visibility: hidden;
					}
					
					&.active {
						max-height: 10000px;
					}

					li {
						display: block;
    					padding: 10px 10px;

    					a,
    					span {
    						font-size: 18px;
    						display: inline-block;

    						@include breakpoint(medium){
    							color: $secondary;
    						}
    					}

					}
				}
				&.chip-tuning .sub {
					width: 130px;
				}
				&.other-services .sub {
					width: 360px;
				}
				&.lang,
				&.lang .sub li {    
					padding: 7px 0 0 10px;

					a, 
					span {
						width: 40px;
						height: 20px;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						font-size: 0;

						&.en {
							background-image: image-url('layout/en-flag.png');
						}

						&.bg {
							background-image: image-url('layout/bg-flag.png');
						}
					}
				}

				&.lang {
					.sub {
						width: 80px;
						right: -8px;
						text-align: center;
						background-color: transparent;
						padding-top: 10px;

						@include breakpoint(medium){
							width: 56px;
						}

						&:before {
							@include breakpoint(medium){
								content: "";
								display: block;
								@include position(absolute, 10px 0 0 0);
								background-color: $light;
							}
						}
						
						li {
							padding: 0 0 15px;
							a, 
							span {
								border: 1px solid $dark;
							}
						}
					}
				}
			}
		}

		#nav-trigger {
			@include position(absolute, 20px null null 30px);
		    display: inline-block;
		    font-size: 36px;
		    height: 31px;
		    width: 31px;
		    color: $third;

		    &:hover {
		    	color: $third;
		    }

		    &:visited,
		    &:active {
		    	color: $third;
		    }

		    span {
		    	@include position(absolute, 0 null null 0);
				display: block;
				width: 100%;
				height: 100%;
		    	transition: all 0.3s ease-in-out;
				
				&.open {
					opacity: 1;
					transform: rotate(0deg);
				}
		    	&.close {
		    		opacity: 0;
		    		transform: rotate(-45deg);
		    	}
		    }

		    &.active {
		    	span {
		    		&.open {
		    			opacity: 0;
		    			transform: rotate(45deg);
		    		}

		    		&.close {
		    			opacity: 1;
		    			transform: rotate(0deg);
		    		}
		    	}
		    }

		    @include breakpoint(medium){
		    	display: none;	
		    }
		}
	}
}