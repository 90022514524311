.image-responsive {
	max-width: 100%;
	display: inline-block;
	margin-bottom: 15px;
}

.pagination {
	padding: 0;
	margin: 0;
	list-style-type: none;
	overflow: hidden;
	text-align: center;
	display: inline-block;

	li {
		display: block;
		float: left;
		a,
		span {
			display: block;
			border: 1px solid $light;
			padding: 0;
			margin: 0;
			min-height: 33px;
			min-width: 33px;
			padding: 3px;
	    	line-height: 1.5;
	    	transition: all 0.3s ease-in-out;

	    	&:hover {
    			background-color: $light;
    			color: $secondary;
    			text-decoration: none;
	    	}
		}

    	&:first-child,
    	&:last-child {
			line-height: 1.3;
    	}
		
    	&.active {
    		a,
    		span {
    			background-color: $light;
    			color: $secondary;
    		}
    	}
	}
}

label.error {
	background-color: $alert;
    display: block;
    padding: 4px 8px;
    border-radius: 4px;
    margin-top: 12px;
    position: relative;

    &:before {
    	content: "";
    	width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 12px 8px;
		border-color: transparent transparent $alert transparent;
		@include position(absolute, -12px null null 12px);
    }
}

.responsive-video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

#linker {
	list-style-type: none;
	padding: 0;
	margin: 0 0 40px 0;

	li {
		display: inline-block;

		a {
			text-transform: capitalize;    
			font-size: 16px;
    		margin-right: 20px;
		}
	}
}

.auth-panel {
	@include position(fixed, 120px 0 null null);
	z-index: 100;

	a {
		color: $secondary;
		display: inline-block;
		font-size: 16px;
		text-transform: uppercase;
		padding: 10px 10px 10px 15px;
		background-color: lightblue;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: $secondary;
			color: $primary;
		}
	}
}

a.close {
	i {
		color: $secondary;
	}
}

.slideHolder {
	margin-top: 20px;
}

img {
	display: inline-block;
    max-width: 100%;
}