/*
 * Color variables
 */

$dark : 		#333;
$light: 		#fff;
$primary: 		#fcfcfc;
$secondary: 	#101010;
$third: 		#fcfcfc;
$alert:			#FF3838;
$warning:		#a25000;


/*
 * Config
 */

$image-url-path: '../images/';


$breakpoints: ( 
	'tiny': 456px, 
	'small': 640px, 
	'medium': 768px, 
	'large': 992px, 
	'giant': 1200px
);