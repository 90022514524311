//Burbon Helpers

@function is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@function unpack($shorthand, $length: 4) {
  @for $i from 1 through $length {
    @if (length($shorthand) < $i) {
      $shorthand: append($shorthand, nth($shorthand, floor($i / 2)));
    }
  }
  @return $shorthand;
}

@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $coordinates: unpack($coordinates);

  $offsets: (
    top:    nth($coordinates, 1),
    right:  nth($coordinates, 2),
    bottom: nth($coordinates, 3),
    left:   nth($coordinates, 4)
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if is-length($value) {
      #{$offset}: $value;
    }
  }
}

@mixin breakpoint($point) {
    @if map-has-key($breakpoints, $point) {
        @media (min-width: map-get($breakpoints, $point)) { @content; }
    }
    @else {
        @warn "The breakpoint #{$point} is not associated with a min-width.";
    }
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}